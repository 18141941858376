import React from 'react'
import { Switch } from 'react-router-dom'
import VerifiedRoute from '../../components/AdminRoute'
import { CLIENT_PATH } from '../../paths'
import Client from '../Client/Client'


export default function Main() {
	
	return (
		<Switch>
			<VerifiedRoute path={CLIENT_PATH} component={Client}/>
		</Switch>
	)
}
