import React from 'react'
import { Redirect } from 'react-router-dom'
import useAuth from '../data-hooks/useAuth'
import { VERIFY_EMAIL_PATH } from '../paths'
import ProtectedRoute from './ProtectedRoute'

interface Props {
	children?: any,
	path: string,
	component?: any,
}

export default function VerifiedRoute({ children, path, component }: Props) {
	
	const { user } = useAuth()
	
	if (!user?.emailVerified) {
		return <Redirect to={VERIFY_EMAIL_PATH} />
	}

	return <ProtectedRoute path={path} component={component}>{children}</ProtectedRoute>
}
