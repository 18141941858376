import { Client, LinearProjectMap, LinearStateMap } from '../types/ClientTypes'
import beam from './beam'
import { Issue } from '@linear/sdk'

export const getClient = (id:string) => beam.get<Client>(`/api/clients/${id}`)
 
export const getClientActiveIssues = (clientId: string) => beam.get<Issue[]>(`/api/clients/${clientId}/issues`)

export const getClientProjects = (clientId: string) => beam.get<LinearProjectMap>(`/api/clients/${clientId}/projects`)

export const getStateMap = () => beam.get<LinearStateMap>('/api/linear/stateMap')
