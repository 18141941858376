import React from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { getAuth, User, sendEmailVerification } from 'firebase/auth'
import { getCurrentAccount } from '../api/accounts'
import { Page } from 'react-ui-scaffold'
import { CionAccount } from '../types/UserTypes'


const AuthContext = createContext<{
	user: User | null,
	isLoading: boolean,
	logOut(): any
	account: CionAccount,
	error: any,
	verifyEmail(): Promise<void>
		}>(null as any)

export function AuthProvider({ children }:any){
	const [user, setUser] = useState<User | null>(null)
	const [isLoading, setIsLoading] = useState(true)
	const [account, setAccount] = useState<CionAccount>(null as any) // null as any, beacsue if you are signed out the rest of the app won't render anyway
	const [error, setError] = useState<any>()
	
	useEffect(() => {
		const auth = getAuth()
		setError(null)
		
		const unsubAuth = auth.onAuthStateChanged(async (newUser) => {
			if (newUser){
				const acc = await getCurrentAccount().catch((res) => {
					setError(res)
					auth.signOut()
				})
				setAccount(acc || null as any)
				
				if (!account){
					setError({ error: "You don't have access to this portal" })
				}
			} else {
				setAccount(null as any)
			}
			
			setUser(newUser)
			setIsLoading(false)
		})
		return unsubAuth
	}, [])
	
	// Hook/Provoider files shouldn't be responsible for UI but we're making an exception
	if (isLoading){
		return <Page loading/>
	}
	
	const logOut = async () => {
		await getAuth().signOut()
		setUser(null)
	}
	
	const verifyEmail = async () => sendEmailVerification(user as User)
	
	return <AuthContext.Provider value={{
		user,
		isLoading,
		account,
		logOut,
		verifyEmail,
		error,
	}}>
		{children}
	</AuthContext.Provider>
}

export default function useAuth() {
	const val = useContext(AuthContext)
	if (!val){
		throw new Error('useAuth outside provider!')
	}
	return val
}
