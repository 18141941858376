import React from 'react'
import { Page } from 'react-ui-scaffold'
import NavBar from '../../../components/NavBar/NavBar'
import useClientLinearProfile, { ClientLinearProfileProvider } from '../../../data-hooks/useClientLinearProfile'
import LinearIssue from './LinearIssue/LinearIssue'

function InnerLinearSummary() {
	const { activeIssues, isLoading } = useClientLinearProfile()
	return (
		<div>
			<NavBar title='This Week' />
			<Page loading={isLoading}>
				{activeIssues.map(iss => !iss.canceledAt && <LinearIssue issue={iss} key={iss.id}/>)}
			</Page>
		</div>
		
	)
}

export default function LinearSummary({ match }:any){
	return <ClientLinearProfileProvider clientId={match.params.clientId}>
		<InnerLinearSummary/>
	</ClientLinearProfileProvider>
}
