import { Issue } from '@linear/sdk'
import React from 'react'
import useClientLinearProfile from '../../../../data-hooks/useClientLinearProfile'

interface Props{
	issue:Issue
}

export default function ProjectCell({ issue }:Props) {
	
	const { projectMap } = useClientLinearProfile()
	//@ts-ignore
	const project = projectMap[issue._project.id]
	
	return (
		<div className='ProjectCell'>
			{project?.name}
		</div>
	)
}
