import React, { useEffect } from 'react'
import { Redirect } from 'react-router'
import useAuth from '../../data-hooks/useAuth'
import { MAIN_PATH } from '../../paths'

export default function Logout() {
	const { logOut } = useAuth()
	
	useEffect(() => logOut,[])
	
	return <Redirect to = {MAIN_PATH}/>
}
