import React, { useMemo } from 'react'

interface Props{
	lineStyle?: React.CSSProperties
	style?: React.CSSProperties,
	text: string,
}

const MultiLineText = ({ text, style, lineStyle }: Props) => {
	const lines = useMemo(() => {
		const showText: string = (text || '')
		const lineArr = showText.split(/\n/g)
		return lineArr
	}, [text])

	return <div style={style}>
		{lines.map(((ln: string, i) => <div style={lineStyle} key={i}>
			{ln}
		</div>))}
	</div>
}

export default MultiLineText
