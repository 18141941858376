import React from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Main from './pages/Main/Main'
import { AuthProvider } from './data-hooks/useAuth'
import ProtectedRoute from './components/ProtectedRoute'
import { CREATE_ACCOUNT_PATH, LOGIN_PATH, MAIN_PATH, LOGOUT_PATH } from './paths'
import Login from './pages/Login/Login'
import Logout from './pages/Logout/Logout'
import CreateAccount from './pages/Login/CreateAccount'
import './stylesheets/menuStyles.css'
import './stylesheets/popupStyles.css'
import PopupProvider from './components/Popup/PopupProvider'
import { useScreenSize } from './hooks/useScreenSize'

function App() {
	
	const { screenSize } = useScreenSize()
	
	return (
		<PopupProvider>
			<div className={`App ${screenSize}`}>
				<AuthProvider>
					<Router>
						<Switch>
							<Route path={LOGIN_PATH} component={Login} />
							<Route path={CREATE_ACCOUNT_PATH} component={CreateAccount} />
							<Route path={LOGIN_PATH} component={Login} />
							<Route path={LOGOUT_PATH} component={Logout} />
							<ProtectedRoute path={`${MAIN_PATH}/`} component={Main} />
						</Switch>
					</Router>
				</AuthProvider>
			</div>
		</PopupProvider>
		
	)
}

export default App
