import React from 'react'
import useAuth from '../../data-hooks/useAuth'

export default function UserMenu() {

	const { account, logOut } = useAuth()

	return (
		<div className='UserMenu' style={{ paddingTop: 10 }}>
			<div style={{ padding: '5px 30px' }}>
				{account?.email}
			</div>

			<button className="button wide negative" onClick={logOut}>
				Sign Out
			</button>
		</div>
	)
}
