import React from 'react'
import { Route, Switch } from 'react-router'
import { CLIENT_PATH, CLIENT_TICKETS_PATH } from '../../paths'
import LinearSummary from './LinearSummary/LinearSummary'

export default function Client() {

	return (
		<div>
			<Switch>
				<Route path={`${CLIENT_TICKETS_PATH}`} component={LinearSummary} />
				<Route path={`${CLIENT_PATH}`} component={LinearSummary} />
			</Switch>
		</div>
	)
}
