import { Issue } from '@linear/sdk'
import React from 'react'
import useClientLinearProfile from '../../../../data-hooks/useClientLinearProfile'

interface Props{
	issue:Issue
}

function StatusCellInternal({ issue }:Props){
	const { stateMap } = useClientLinearProfile()
	/*@ts-ignore*/
	const getState = (issue: Issue) => stateMap[issue._state.id]
	
	return <span className = 'statusCell' style={{ backgroundColor: getState(issue)?.color }}>
		{getState(issue)?.name}
	</span>
}

export default function StatusCell({ issue }:Props) {
	return (
		<div className='StatusCell cell'>
			<div className='statusBadge'>
				<StatusCellInternal issue={issue}/>
			</div>
		</div>
	)
}
