import React from 'react'

interface Props{
	priority: string
}

const COLOR_MAP:any = {
	'No priority': '',
	'Low': '',
	'Medium': '',
	'High': 'rgb(221, 100, 1)',
	'Urgent': 'red',
}


export default function PriorityCell({ priority }:Props) {
	return (
		<div className="PriorityCell cell" style={{ color: COLOR_MAP[priority] }}>
			{priority}
		</div>
	)
}
