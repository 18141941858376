import React from 'react'
import './NavBar.css'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MenuButton } from 'react-ui-scaffold'
import UserMenu from './UserMenu'

interface Props{
	children?: any,
	title?: string
}

export default function NavBar({ children, title }:Props) {
	return (
		<div className='NavBar'>
			
			<div className="navContainer flexY">
				<div className="titleBox">
					{title}
				</div>

				<div className="childrenBox">
					{children}
				</div>

				<div className="controls">
					<MenuButton menuContent={UserMenu} menuStyle={{ maxWidth: 'fit-content' }}>
						<span className="icon">
							<FontAwesomeIcon icon={faUserCircle} />
						</span>
					</MenuButton>
				</div>
			</div>
			
		</div>
	)
}
