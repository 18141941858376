import React from 'react'
import useAuth from '../../data-hooks/useAuth'
import { Redirect } from 'react-router-dom'

export default function CreateAccount() {
	
	const { user } = useAuth()

	if (user) {
		return <Redirect to='/' />
	}
	
	return (
		<div>
			
		</div>
	)
}
