import React, { useContext, createContext, useEffect, useState } from 'react'
import { Client, LinearProjectMap, LinearStateMap } from '../types/ClientTypes'
import { Issue } from '@linear/sdk'
import { getClientActiveIssues, getClient, getStateMap, getClientProjects } from '../api/clients'

const Context = createContext<{
	client:Client | null,
	activeIssues:Issue[],
	stateMap: LinearStateMap
	isLoading: Boolean,
	projectMap: LinearProjectMap
}>(null as any)


interface ProviderProps{
	children:any,
	clientId: string
}

export const ClientLinearProfileProvider = ({ children, clientId }: ProviderProps) => {
	const [client, setClient] = useState<Client | null>(null)
	const [activeIssues, setActiveIssues] = useState<Issue[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [stateMap, setStateMap] = useState<LinearStateMap>({})
	const [projectMap, setProjectMap] = useState<LinearProjectMap>({})
	
	useEffect(() => {
		setIsLoading(true)
		Promise.all([
			getClient(clientId),
			getClientActiveIssues(clientId),
			getStateMap(),
			getClientProjects(clientId),
		]).then(([newClient, newIssues, newStateMap, newProjects]) => {
			setClient(newClient)
			setActiveIssues(newIssues)
			setIsLoading(false)
			setStateMap(newStateMap)
			setProjectMap(newProjects)
		})
	}, [clientId])

	return <Context.Provider value={{
		activeIssues,
		client,
		isLoading,
		stateMap,
		projectMap,
	}}>
		{ children }
	</Context.Provider>
}

const useClientLinearProfile = () => {
	const val = useContext(Context)
	if (!val) {
		throw new Error('useClient outside provider!')
	}
	return val
}

export default useClientLinearProfile
