import React, { useState } from 'react'
import { Issue } from '@linear/sdk'
import './LinearIssue.css'
import PriorityCell from './PriorityCell'
import StatusCell from './StatusCell'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import MultiLineText from '../../../../components/MultiLineText/MultiLineText'
import ProjectCell from './ProjectCell'

interface Props{
	issue: Issue
}

export default function LinearIssue({ issue }:Props) {
	const [expanded, setExpanded] = useState(false)
	
	return (
		<div className='LinearIssue'>
			<div className='flexY'>
				<button className="expander button plain" onClick={() => setExpanded(!expanded)}>
					<FontAwesomeIcon icon={expanded ? faMinus : faPlus}/>
				</button>
				
				<StatusCell issue={issue} />
				<PriorityCell priority={issue.priorityLabel} />

				<ProjectCell issue={issue}/>

				<div className='title'>
					{issue.title}
				</div>
			</div>
			
			{expanded && <div className="desc">
				<MultiLineText text={issue.description || ''} lineStyle={{
					minHeight: '22px',
					width: '100%',
				}}/>
			</div>}
		</div>
	)
}
