import React from 'react'
import { Route } from 'react-router-dom'
import useAuth from '../data-hooks/useAuth'
import Login from '../pages/Login/Login'

interface Props{
	children?: any,
	path: string,
	component?: any,
}

export default function ProtectedRoute({ children, path, component }:Props) {
	
	const { user, account } = useAuth()
	
	if (!(user && account)){
		return <Route path={path} component={Login}/>
	}
	
	return <Route path={path} component={component}>{children}</Route>
}
